import { useMutation } from 'react-query'

import { client as request } from '../utils/api-client'

export function useLogin({ config = {} } = {}) {
  return useMutation(async body => {
    const response = await request('/auth/login', {
      method: 'POST',
      body,
    }).catch(error => Promise.reject(error))

    if (response.statusCode !== 200) {
      throw response.message
    }

    return 'OK'
  }, config)
}

export function useLogout({ config = {} } = {}) {
  return useMutation(async () => {
    const response = await request('/auth/logout', {
      method: 'POST',
    }).catch(error => Promise.reject(error))

    if (response.statusCode !== 200) {
      throw response.message
    }

    return 'OK'
  }, config)
}
