import { useQuery } from 'react-query'

import { client as request } from '../utils/api-client'

export function useMe({ options = {} } = {}) {
  return useQuery({
    queryKey: ['me'],
    queryFn: async () => {
      const response = await request(
        '/auth/me',
        {
          method: 'GET',
        },
        'auth'
      ).catch(error => Promise.reject(error))

      return response.data
    },
    config: { ...options },
  })
}

export function useTest() {}
