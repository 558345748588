import React from 'react'

import { useAuth } from './auth-context'

const UserContext = React.createContext()

function UserProvider(props) {
  const { user } = useAuth()

  return <UserContext.Provider {...props} value={user} />
}

function useUser() {
  const context = React.useContext(UserContext)

  if (context === undefined) {
    throw new Error('useUser debe ser usado dentro del provider UserProvider')
  }

  return context
}

export { UserProvider, useUser }
