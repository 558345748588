import i18n from 'i18next'
import axios from 'axios'
import { isIE } from 'react-device-detect'
import qs from 'qs'
const LOCAL_STORAGE_KEY = process.env.REACT_APP_LOCAL_STORAGE_KEY
const VERSION_NUMBER = process.env.REACT_APP_VERSION_NUMBER
const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL

const FETCHPATHS = {
  recruiter: {
    url: process.env.REACT_APP_API_URL,
    api: process.env.REACT_APP_API_VERSION,
  },
  auth: {
    url: process.env.REACT_APP_AUTH_URL,
    api: process.env.REACT_APP_AUTH_VERSION,
  },
  core: {
    url: process.env.REACT_APP_API_URL,
    api: process.env.REACT_APP_API_CORE_VERSION,
  },
  sso: {
    url: process.env.REACT_APP_SSO_API_URL,
    api: process.env.REACT_APP_SSO_API_VERSION,
  },
}
const APITOKENS = {
  recruiter: {
    api: process.env.REACT_APP_API_TOKEN,
  },
  auth: {
    api: process.env.REACT_APP_AUTH_TOKEN,
  },
  core: {
    api: process.env.REACT_APP_API_CORE_TOKEN,
  },
  sso: {
    api: process.env.REACT_APP_SSO_API_TOKEN,
  },
}
const API_URL = {
  recruiter: process.env.REACT_APP_API_URL,
  auth: process.env.REACT_APP_API_URL,
  catalog: process.env.REACT_APP_CATALOG_API_URL,
}

const API_VERSION = {
  recruiter: process.env.REACT_APP_API_VERSION,
  auth: process.env.REACT_APP_API_VERSION,
  catalog: process.env.REACT_APP_CATALOG_API_VERSION,
}

const APP_TOKEN = {
  catalog: process.env.REACT_APP_CATALOG_API_TOKEN,
}

const isUIWebView = /((iPhone|iPod|iPad).*AppleWebKit(?!.*Version)|; wv)/i.test(
  navigator.userAgent
)
async function getCSRFToken() {
  if (process.env.NODE_ENV === 'test') return '__TESTING__'
  const token = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content')

  if (!token) {
    const urlCsrf = window.location.href.split('/').includes('restore-password')
    const routeCsrf = urlCsrf ? 'csrf-token-no-app' : 'csrf-token'
    const response = await window
      .fetch(`${FETCHPATHS.auth.url}${FETCHPATHS.auth.api}/auth/${routeCsrf}`, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'application-token': APITOKENS.auth.api,
          'app-token': APITOKENS.recruiter.api,
        },
        credentials: 'include',
      })
      .catch(error => {
        let tempMessage = error
        if (error && error.message) {
          tempMessage = error.message
        }
        if (typeof tempMessage === 'string') {
          tempMessage = tempMessage.toLowerCase()
        }
        if (
          [
            'networkerror when attempting to fetch resource.', // firefox
            'failed to fetch', // chrome
          ].includes(tempMessage)
        ) {
          throw Error('Servicio temporalmente no disponible')
        }
        throw Error(error)
      })
    const { csrfToken } = await response.json()
    setCSRFToken(csrfToken)
    return csrfToken
  }
  return token
}

function setCSRFToken(token) {
  document
    .querySelector('meta[name="csrf-token"]')
    .setAttribute('content', token)
}
function getToken() {
  return isUIWebView ? window.localStorage.getItem(LOCAL_STORAGE_KEY) : ''
}

async function client(
  endpoint,
  { body, method = 'GET', ...customConfig } = {},
  api = 'recruiter'
) {
  const token = getToken()

  const headers = {
    'content-type': 'application/json',
    'api-token': APITOKENS[api].api,
    'gateway-token': APITOKENS[api].gateway,
    'ui-version': VERSION_NUMBER,
    'accept-language': localStorage['accept-language'] || 'es-CR',
  }
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  const config = {
    method,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
    credentials: 'include',
  }
  if (body) {
    config.body = JSON.stringify(body)
  }
  if (!['GET', 'HEAD', 'OPTIONS'].includes(method.toUpperCase())) {
    config.headers['X-CSRF-Token'] = await getCSRFToken()
  }
  return window
    .fetch(`${FETCHPATHS[api].url}${FETCHPATHS[api].api}${endpoint}`, config)
    .then(async r => {
      // Venció el tiempo del token, por lo tanto
      // se quita el token y se hace redirect
      if (
        r.status === 401 &&
        !endpoint.includes('login') &&
        !endpoint.includes('me') && // este no deberia estar aqui, pero sino se pone se vuelve loco login, no encontre como mejorarlo
        !isUIWebView // movil no deberia ir al home
      ) {
        config.headers['X-CSRF-Token'] = await getCSRFToken()
        await window.fetch(
          `${FETCHPATHS.recruiter.url}${FETCHPATHS.recruiter.api}/auth/logout`,
          {
            ...config,
            method: 'POST',
          }
        ).catch(error => {
          let tempMessage = error?.message || error
          if (typeof tempMessage === 'string') {
            tempMessage = tempMessage.toLowerCase()
          }
          if (
            [
              'networkerror when attempting to fetch resource.', // firefox
              'failed to fetch', // chrome
            ].includes(tempMessage)
          ) {
            throw Error(i18n.t('Servicio temporalmente no disponible'))
          }
          throw Error(error)
        })
        setCSRFToken('')
        window.location.href = `${PUBLIC_URL}?error=session_expired`
        throw new Error('Token Expired')
      }
      if (r.status !== 200) {
      }
      return r
    })
    .then(r => r.json())
    .catch(error => {
      let tempMessage = error?.message || error
      if (typeof tempMessage === 'string') {
        tempMessage = tempMessage.toLowerCase()
      }
      if (
        [
          'networkerror when attempting to fetch resource.', // firefox
          'failed to fetch', // chrome
        ].includes(tempMessage)
      ) {
        throw Error(i18n.t('Servicio temporalmente no disponible'))
      }
      throw Error(error)
    })
}

export { client, getCSRFToken, setCSRFToken, getToken }
