import React from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { useLogin, useLogout } from '../mutations/auth'
import { useMe } from '../queries/auth'
import LoadingFallback from '../components/LoadingFallback'

const AuthContext = React.createContext()
AuthContext.displayName = 'AuthContext'

function AuthProvider(props) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const {
    data: user,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    status,
    refetch,
  } = useMe({
    options: {
      async queryFn() {
        return { user: null }
      },
      onError() {},
      placeholderData: { user: null },
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  })

  const { mutateAsync: mutateLogin } = useLogin({
    config: {
      onSuccess: () => {
        refetch()
      },
    },
  })
  const { mutateAsync: mutateLogout } = useLogout({
    config: {
      onSuccess: () => {
        navigate(process.env.PUBLIC_URL)
        setTimeout(() => {
          queryClient.invalidateQueries('me')
        }, 500)
        refetch()
      },
    },
  })
  const login = React.useCallback(async body => {
    await mutateLogin(body).catch(err => {
      return Promise.reject(err)
    })
  }, [])
  const logout = React.useCallback(async () => {
    await mutateLogout().catch(err => {
      //TODO: Manage logout error?
      return Promise.resolve()
    })
  }, [])
  const values = React.useMemo(
    () => ({ user, login, logout }),
    [user, login, logout]
  )

  if (isLoading || isIdle) {
    return <LoadingFallback />
  }

  if (isError) {
    return (
      <div style={{ color: 'red' }}>
        <p>Uh oh... Ha ocurrido un problema. Trate de refrescar el App.</p>
        <pre>{error.message}</pre>
      </div>
    )
  }

  if (isSuccess) {
    return <AuthContext.Provider value={values} {...props} />
  }

  throw new Error(`Unhandled status: ${status}`)
}

function useAuth() {
  const context = React.useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth debe ser utilizado dentro de AuthProvider')
  }

  return context
}

export { AuthProvider, useAuth }
