import React from 'react'
import { useTranslation } from 'react-i18next'
/** @jsxImportSource @emotion/react */
import { keyframes } from '@emotion/react'
import { FaSpinner } from 'react-icons/fa'

const spin = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
})

/**
 * This component allows you to display a spinner.
 * @param {objet} props - Props from the parent component
 */
const Spinner = React.memo(
  props => {
    const { t } = useTranslation()
    return (
      <FaSpinner
        css={{ animation: `${spin} 1s linear infinite` }}
        aria-label={t('loading')}
        {...props}
      />
    )
  },
  (prevProps, nextProps) =>
    JSON.stringify(prevProps) === JSON.stringify(nextProps)
)

export default Spinner
