/** @jsxImportSource @emotion/react */
import React from 'react'
import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next'

import Spinner from './SmallSpinner'
import Colors from './Colors'

export default function LoadingFallback() {
  const { t } = useTranslation()
  return (
    <div css={css(mainStyle, customCss.recruiter)}>
      <Spinner
        style={{
          width: 20,
          height: 20,
          color: 'white',
        }}
      />
    </div>
  )
}

const mainStyle = css`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
`

const customCss = {
  recruiter: css`
    background-color: ${Colors.darkJungleGreen};
  `,
}
