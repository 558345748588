import React from 'react'
import { useUser } from './context/user-context'
import LoadingFallback from './components/LoadingFallback'

const loadAuthenticatedApp = () => import('./AuthenticatedApp')
const AuthenticatedApp = React.lazy(loadAuthenticatedApp)
const UnAuthenticatedApp = React.lazy(() => import('./UnAuthenticatedApp'))

function App() {
  const user = useUser()
  return (
    <React.Suspense fallback={<LoadingFallback />}>
      {user ? <AuthenticatedApp /> : <UnAuthenticatedApp />}
    </React.Suspense>
  )
}

export default App
