import React from 'react'
import { AuthProvider } from './auth-context'
import { UserProvider } from './user-context'
import { QueryClientProvider, QueryClient } from 'react-query'
import configs from '../utils/providersConfiguration'
import { BrowserRouter as Router } from 'react-router-dom'

const queryClient = new QueryClient(configs.reactQuery)
function AppProvider({ children }) {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <UserProvider>{children}</UserProvider>
        </AuthProvider>
      </QueryClientProvider>
    </Router>
  )
}

export default AppProvider
