/* eslint-disable import/no-anonymous-default-export */
export default {
  reactQuery: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  },
  theme: {
    pixelsToRem(px) {
      let pixels = px
      if (typeof px === 'string') {
        pixels = px.includes('px') ? Number(px.replace('px', '')) : Number(px)
      }

      return `${pixels / 16}rem;`
    },
  },
}
