/**
 * List of colors to customize the app
 */
const common = {
  cerulean: '#046FA9',
  hawkesBlue: '#BFD7FF',
  lightSkyBlue: '#94BAF9',
  prussianBlue: '#004266',
  sanMarino: '#506D9C',
  solitude: '#E8F0FF',
  white: '#FFFFFF',
}

const recruiter = {
  ...common,
  azureishWhite: '#d4e4ed',
  cadetBlueCrayola: '#A0B9C8',
  columbiaBlue: '#c9dfe7',
  darkJungleGreen: '#0d1f2d',
  darkSlateGray: '#214A61',
  honoluluBlue: '#0672ab',
  midnightGreen: '#08415c',
  redSalsa: '#F93545',
  richElectricBlue: '#1896d8',
}

export default recruiter
